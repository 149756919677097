/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const IMPORTING = 'IMPORTING/'; 
export const types = {
    GET_IMPORTING_REQUEST: IMPORTING + 'GET_IMPORTING_REQUEST',
    GET_IMPORTING_SUCCESS: IMPORTING + 'GET_IMPORTING_SUCCESS',
    GET_IMPORTING_ERROR: IMPORTING + 'GET_IMPORTING_ERROR',
    ADD_IMPORT: 'ADD_IMPORT',
    ADD_IMPORT_REQUEST: IMPORTING + 'ADD_IMPORT_REQUEST',
    ADD_IMPORT_ERROR: IMPORTING + 'ADD_IMPORT_ERROR',
    ADD_IMPORT_SUCCESS: IMPORTING + 'ADD_IMPORT_SUCCESS',
    DELETE_IMPORT: 'DELETE_IMPORT',
    DELETE_IMPORT_REQUEST: IMPORTING + 'DELETE_IMPORT_REQUEST',
    DELETE_IMPORT_ERROR: IMPORTING + 'DELETE_IMPORT_ERROR',
    DELETE_IMPORT_SUCCESS: IMPORTING + 'DELETE_IMPORT_SUCCESS',
    UPDATE_PROGRESS: IMPORTING + 'UPDATE_PROGRESS',
    IMPORTING_ERROR_DETAILS: IMPORTING + 'IMPORTING_ERROR_DETAILS',
    DELETE_IMPORTING: IMPORTING + 'DELETE_IMPORTING',
    TEMPLATE_FILES_IMPORTING: IMPORTING + 'TEMPLATE_FILES_IMPORTING'
};

export const initialState = fromJS({
    importingList: List(),
    importPageCount: -1,
    importingLoading: false,
    progress: 0
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_IMPORTING_REQUEST:
            return state.set('importingLoading', true);
        case types.GET_IMPORTING_ERROR:
            return state.set('importingLoading', false);
        case types.GET_IMPORTING_SUCCESS:
            return state.set('importingLoading', false)
                        .set('importingList', List(action.data))
                        .set('importPageCount', action.importPageCount);
        case types.UPDATE_PROGRESS:
            return state.set('progress', action.progress);
        case types.ADD_IMPORT_SUCCESS:
            return state.set('progress', 0);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getImportingRequest: (page, pageSize, sortBy, direction) => ({
        type: types.GET_IMPORTING_REQUEST,
        page,
        pageSize,
        sortBy,
        direction
    }),
    getImportingSuccess: (importingList, importPageCount) => ({
        type: types.GET_IMPORTING_SUCCESS,
        data: importingList,
        importPageCount
    }),
    getImportingError: () => ({
        type: types.GET_IMPORTING_ERROR
    }),
    addImportRequest: (data, sort) => ({
        type: types.ADD_IMPORT_REQUEST,
        data,
        sort
    }),
    addImportRequestError: (error) => ({
        type: types.ADD_IMPORT_REQUEST_ERROR,
        error
    }),
    addImportRequestSuccess: () => ({
        type: types.ADD_IMPORT_SUCCESS
    }),
    setUploadingProgress: (progress) => ({
        type: types.UPDATE_PROGRESS,
        progress
    }),
    deleteImportRequest: id => ({
        type: types.DELETE_IMPORTING,
        data: id
    }),
};

// Selectors
const selectImporting = state => state.get('importing', initialState);

export const makeSelectImportingList = () =>
    createSelector(selectImporting, importingState => importingState.get('importingList'));

export const makeSelectImportingListLoading = () =>
    createSelector(selectImporting, importingState => importingState.get('importingLoading'));

export const selectProgress = () => 
    createSelector(selectImporting, importingState => importingState.get('progress'));

export const makeSelectImportPageCount = () =>
    createSelector(selectImporting, importingState => importingState.get("importPageCount"));