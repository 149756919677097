import React, { useEffect, useState  } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    actions as ImportingActions,
    types,
    makeSelectImportingList,
    makeSelectImportPageCount,
    makeSelectImportingListLoading
} from '../../reducers/importing';
import { actions as AppActions } from '../../reducers/app';
import { selectUserCompany } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import ImportingEditMenu from "../../containers/ImportingEditMenu/importingEditMenu";
import Chip from '@material-ui/core/Chip';
import './importingTable.css';
import { importTypes } from '../../containers/ModalConductor/ImportsModals/AddImportModal';

const initialSortColumns = [
    {
        id: 'created',
        desc:true
    }
]
let currentSort = initialSortColumns;


function Failures(props) {
    const { row } = props;
    const errorCount = row.original.errorCount;
    
    function handleClick() {
        props.setCurrentModal(types.IMPORTING_ERROR_DETAILS, row.original);
    };

    return <Button id={`importing-error-button-${props.row.index}`} style={{ color: 'red' }} title={`${errorCount}`} onClick={handleClick}>{errorCount}</Button>
}

function Status(props) {
    const { row } = props;
    const status = row.original.status;
    if (status === "COMPLETE") 
        return <Chip label="Complete" style={{backgroundColor:'#00c936', color: 'white'}} />
    else if (status === "COMPLETE_WITH_FAILURES")
        return <Chip label="Completed With Failures"  variant="outlined" style={{backgroundColor:"#FFF3CD", color: '#9A7D27'}} />
    else if (status === "ERROR")
        return <Chip label="Error" color="secondary"/>
    else if (status === "PENDING")
        return <Chip label="Pending" />
    else if (status === "IN_PROGRESS") {
        const progress = "In Progress: " + ((props.row.original.processedCount / props.row.original.recordCount) * 100).toFixed(0) + '%';
        return <Chip label={progress} variant="outlined"/>
    }
}
function ImportingListTable(props) {
    const [pageSize, setPageSize] = useState(25);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    const buildTableColumns = [
        {
            Header: "Import Info",
            id: 'import',
            columns: [
                {
                    Header: "Created",
                    id: 'created',
                    sortable: true,
                    Cell: row => <div title={formatLastAccess(row.original.created)}>{formatLastAccess(row.original.created)}</div>
                },
                {
                    Header: "Type",
                    id: 'type',
                    Cell: row => <div title={row.original.importType}>{getImportTypeMap(row.original.importType)}</div>
                },
                {
                    Header: "Status",
                    id: 'status',
                    minWidth: 125,
                    Cell: row => <Status row={row}/>
                },
            ]
        },
        {
            Header: "Rows Processed",
            id: 'records-imported',
            columns: [
                {
                    Header: "Inserted",
                    Cell: row => <div title={row.original.insertedCount}>{row.original.insertedCount}</div>
                },
                {
                    Header: "Updated",
                    Cell: row => <div title={row.original.updatedCount}>{row.original.updatedCount}</div>
                },
                {
                    Header: "Deleted",
                    Cell: row => <div title={row.original.deletedCount}>{row.original.deletedCount}</div>
                },
                {
                    Header: "Failures",
                    id: 'failures',
                    Cell: row => <Failures row={row} setCurrentModal={props.setCurrentModal}/>
                },
                {
                    Header: "Total",
                    Cell: row => <div title={row.original.processedCount}>{row.original.processedCount}</div>
                },
            ]
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => { 
                const types = importTypes(props.company.toJS().locationTreeType) ;

                for (const type of types) {
                    if (type.key === row.original.importType) {
                       return (
                       <ImportingEditMenu row={row} 
                        setCurrentModal={props.setCurrentModal}
                        currentSortColumn={props.currentSortColumn}
                        sort={currentColumn().data[0]}
                    />)
                    }
                }

                return null;
        }
        }
    ];

    //const [pageNumber, setPageNumber] = useState(0)

    function formatLastAccess(callTime) {
        return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    function getImportTypeMap(importType){
        const types = importTypes(props.company.toJS().locationTreeType, true);
     var map = new Map(); 
        for(var i = 0; i < types.length; i++){ 
            map.set(types[i].key, types[i].value); 
        } 
       var value =  map.get(importType);
        return value;
    }

    useEffect( () => {
        fetchImportingList(pageIndex, pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
    }, [currentSortColumn, pageSize, pageIndex]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }
    
    
    const {
        loading, importing, fetchImportingList, setCurrentModal, importPageCount
    } = props;
    let importingArray = [];

    if (importing) {
      importingArray = importing.toArray();
    }

    useEffect(() => {
        if (importingArray && importingArray.length === 0 && pageIndex > 0 && importPageCount < pageIndex+1) {
            setPageIndex(pageIndex - 1);
            currentSort = [
              {
                desc: currentSortColumn.desc,
                page: pageIndex - 1,
                pageSize: pageSize,
                sortBy: currentSortColumn.id,
              },
            ];
          }
    }, [importingArray && importingArray.length === 0 && pageIndex > 0 && importPageCount < pageIndex+1]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight importing-table"
            showPaginationBottom={true}
            data={importingArray}
            minRows={importingArray && importingArray.length > 0 ? 0 : 5}
            columns={buildTableColumns}
            LoadingComponent={ReactTableLoadingComponent}
            page={pageIndex}
            pages={importPageCount <= 0 ? 1 : importPageCount}
            defaultPageSize={25}
            manual
            sortColumns={sortColumns}
            setSortColumns={setSortColumns}
            currentSortColumn={currentSortColumn}
            setCurrentSortColumn={setCurrentSortColumn} 
            sorted={sortColumns}
            defaultSorted={[
                {
                    id: 'created',
                    desc: true
                }
            ]}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = true;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                    currentSort = [{
                            desc: currentSortColumn.desc,
                            page: pageIndex, 
                            pageSize: pageSize, 
                            sortBy: currentSortColumn.id
                        }
                    ];
                })
            }}
            onPageSizeChange={pageSize => {
                setPageSize(pageSize);
                setPageIndex(0);
                resetScrollInsideTable();
                currentSort = [{
                        desc: currentSortColumn.desc,
                        page: pageIndex, 
                        pageSize: pageSize, 
                        sortBy: currentSortColumn.id
                    }
                ];
            }}
            onPageChange={(pageIndex) => {
                setPageIndex(pageIndex);
                resetScrollInsideTable();
                currentSort = [{
                        desc: currentSortColumn.desc,
                        page: pageIndex, 
                        pageSize: pageSize, 
                        sortBy: currentSortColumn.id
                    }
                ];
            }}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'importing-row-' + rowInfo.index,
                        };
                    } else {
                        return {
                            id: rowInfo ? 'importing-row-' + rowInfo.index : '',
                        };
                    }
                }
            }
        />
    );
}

ImportingListTable.propTypes = {
    importing: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired
};

export function currentColumn() {
	return {
		data: currentSort
	}
}

const mapStateToProps = createStructuredSelector({
    importing: makeSelectImportingList(),
    loading: makeSelectImportingListLoading(),
    importPageCount: makeSelectImportPageCount(),
    company: selectUserCompany()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchImportingList: (pageIndex, pageSize, sortBy, direction) => dispatch(ImportingActions.getImportingRequest(pageIndex, pageSize, sortBy, direction)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportingListTable);