 import { axiosInstance } from './api';
/* Cirrus Admin-Service /locations Controller */
const IMPORTING_ENDPOINT = 'admin-service/imports';

export default class ImportingAPI {
	static getImportingByCompanyId(companyId, page, pageSize, sortBy, direction) {
		return axiosInstance.get(`${IMPORTING_ENDPOINT}/company/${companyId}`, {
            params: {
                direction,
                sortBy,
                page,
                pageSize
            }
        });
    }

	static getImporting() {
		return axiosInstance.get(`${IMPORTING_ENDPOINT}`)
	}

	static getS3ImportLink(companyId, importType, isDelete) {
		return axiosInstance.get(`${IMPORTING_ENDPOINT}/link`, {
				params: {
					'companyId': companyId,
					'importType': importType,
					'isDelete': isDelete
			}
		});
	}

	static getImportingErrorDetails(id) {
		return axiosInstance.get(`${IMPORTING_ENDPOINT}/${id}/errorDetails`)
	}
	
	static getErrorFile(id) {
		return axiosInstance.get(`${IMPORTING_ENDPOINT}/${id}/downloadErrorFile`)
	}

	static deleteImport(id) {
		return axiosInstance.delete(`${IMPORTING_ENDPOINT}/${id}`);
	}
}